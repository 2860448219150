import { createContext } from 'react';
import combineReducers from 'react-combine-reducers';

/**
 * Reducers
 */
import modalReducer from '@store/modal/reducers';
import propertiesReducer from '@store/properties/reducers';

const [rootReducer, initialState] = combineReducers({
	modals: modalReducer,
	properties: propertiesReducer
});

const AppContext = createContext();
export { rootReducer, initialState };
export default AppContext;
