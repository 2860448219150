import StoreProvider from '@store/context';

export const wrapRootElement = StoreProvider;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {

  // Avoid to scroll to top when next page trigger click
  // But this feature should condiser with details page back scroll too
  if (location.pathname.match(/property\/sales/)) {
    return false;
  }
  if (location.pathname.match(/property\/rentals/)) {
    return false;
  }

  return true;
};
