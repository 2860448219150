const initialValue = {
  property_search: false,
  edit_search: false
}

const reducer = (state, action) => {
  if (action.type === 'MODAL_TOGGLE') {
    return {
      ...state,
      [action.payload.name]: action.payload.active
    };
  }
  return state;
};

export default [reducer, initialValue];
