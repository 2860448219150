import React, {useReducer} from 'react';
import AppContext, { rootReducer, initialState } from '@store/store';

const StoreProvider = (props) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  return (
    <AppContext.Provider value={[state, dispatch]}>
      {props.children}
    </AppContext.Provider>
  );
}

export default ({ element }) => (
  <StoreProvider>
    {element}
  </StoreProvider>
)