const initialValue = {
  data: [
    {
      image: '/images/p1.jpg',
      price: '£510,000',
      location: 'Bingley, West Yorkshire',
      description: '4 bedroom house for sale'
    },
    {
      image: '/images/p2.jpg',
      price: '£465,000',
      location: 'Keighley, West Yorkshire',
      description: '3 bedroom apartment for sale'
    },
    {
      image: '/images/p3.jpg',
      price: '£450,000',
      location: 'Shipley, West Yorkshire',
      description: '4 bedroom apartment for sale'
    },
    {
      image: '/images/p1.jpg',
      price: '£510,000',
      location: 'Bingley, West Yorkshire',
      description: '4 bedroom house for sale'
    },
    {
      image: '/images/p2.jpg',
      price: '£465,000',
      location: 'Keighley, West Yorkshire',
      description: '3 bedroom apartment for sale'
    },
    {
      image: '/images/p3.jpg',
      price: '£450,000',
      location: 'Shipley, West Yorkshire',
      description: '4 bedroom apartment for sale'
    },
    {
      image: '/images/p1.jpg',
      price: '£510,000',
      location: 'Bingley, West Yorkshire',
      description: '4 bedroom house for sale'
    },
    {
      image: '/images/p2.jpg',
      price: '£465,000',
      location: 'Keighley, West Yorkshire',
      description: '3 bedroom apartment for sale'
    },
    {
      image: '/images/p3.jpg',
      price: '£450,000',
      location: 'Shipley, West Yorkshire',
      description: '4 bedroom apartment for sale'
    }
  ]
}

const reducer = (state, action) => {
  if (action.type === 'GET_PROPERTIES') {
    return {
      ...state,
    };
  }
  return state;
};

export default [reducer, initialValue];
